import { RouteComponentProps } from "@reach/router"
import * as React from "react"
import axios from "axios"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
import Header from "../Header"
import Button from "../Button"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./Product.scss"

type ProductType = {
  condition: string
  description: string
  id: number
  price: string
  title: string
  createdAt: string
}

type Props = RouteComponentProps<{
  productId: string
}>

export default function Product({ productId = "" }: Props) {
  const [data, setData] = useState<ProductType | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true)
      const response = await axios
        .get(`${process.env.GATSBY_BASE_URL}/api/product`, {
          params: {
            id: productId,
          },
        })
        .then(response => {
          setData(response.data)
          setLoading(false)
        })
        .catch(error => console.error("timeout exceeded"))
    }
    fetchProduct()
  }, [])

  if (loading || !data) {
    return <div>Loading</div>
  }
  const { condition, description, id, price, title, createdAt } = data
  return (
    <Header>
      <div className="product columns is-variable is-3">
        <div className="column is-7">
          <div className="product__carousel">
            <Carousel statusFormatter={() => null}>
              <img src="https://m.media-amazon.com/images/I/61N4RhDqCrL._AC_SY450_.jpg" />
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8NnJVHLbCB02E4XJ7Py6Nu1JuXP0cW-YovC-mE_mWB-xHuunK7Z1EpD9zdFduiU8hR_8&usqp=CAU" />
              <img src="https://m.media-amazon.com/images/I/61x+WPic8OL._AC_SY450_.jpg" />
            </Carousel>
          </div>
        </div>
        <div className="column">
          <div className="is-size-3 has-text-weight-bold">{title}</div>
          <div className="is-size-3 has-text-weight-bold">${price}</div>
          <div className="my-6">
            <Button classname="is-lingonberry is-size-5 has-text-weight-bold">
              Buy Now
            </Button>
          </div>
          <div className="is-flex is-justify-content-space-between">
            <div>
              <span className="icon-text">
                <span className="icon">
                  <i className="far fa-calendar-alt" />
                </span>
                <span>
                  Listed on: {DateTime.fromISO(createdAt).toFormat("LLLL d, y")}
                </span>
              </span>
            </div>
          </div>
          <div className="my-2">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-cube" />
              </span>
              <span>Condition: {condition}</span>
            </span>
          </div>
          <div className="mt-6">
            <div className="is-size-5 has-text-weight-bold my-3">
              Description
            </div>
            <div>{description}</div>
          </div>
        </div>
      </div>
    </Header>
  )
}
