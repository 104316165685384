import { RouteComponentProps, Router } from "@reach/router"
import React from "react"
import Product from "../components/product/Product"

const Home = (props: RouteComponentProps) => (
  <section className="hero is-medium is-bold">
    <div className="hero-body">
      <div className="container">
        <h1 className="title">
          <div className="icon is-large has-text-info">
            <i className="fas fa-chart-line" />
          </div>{" "}
          Products
        </h1>
      </div>
    </div>
  </section>
)

export default function ProductRouter() {
  return (
    <Router basepath="/product" component={({ children }) => <>{children}</>}>
      <Home path="/" />
      <Product path=":productId" />
    </Router>
  )
}
